import { useContext, useEffect } from 'react';
import PCPcss from '../css/PCsystemProduct.module.css'
import { UserContext } from '../Class/UserPara';

const PCsystemProduct = () => {

    const content = (
        <section className={PCPcss.sectionMain}>

                {/* One Click Done Tools */}
                <div className={PCPcss.ProductType}>
                    <p className={PCPcss.ProductTypeName}>One Click Done Tools</p>
                    <p className={PCPcss.ProductTypeDesc}>- 為你帶來100%的資料準確性</p>
                    <p className={PCPcss.ProductTypeDesc}>一個按鍵，瞬間將資料處理，成為你公司需要的行政/會計報表, 滙入格式或資料輸入</p>
                    
                    <hr />
                    
                    <div className={PCPcss.DisplayProduct}>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>資料整合工具</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>一個按鍵，瞬間將其他格式資料轉換成你公司格式</li>
                                    <li>瞬間將不同系統的資料整合並處理好</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>1,200 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢資料整合工具的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>電腦檔案整理工具</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>一個按鍵，瞬間將一整個文件夾的資料處理 (重新命名或分別移動到其他不同文件夾)</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>1,200 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢電腦檔案整理工具的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>自動發電郵工具</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>一個按鍵，瞬間將數千封電郵以不同收件人及自訂不同標題及內容發出</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>2,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢自動發電郵工具的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>發單工具</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>一個按鍵，即是將所需資料抽取並輸出發票或任何格式 (客戶需要自行設計輸出格式)</li>
                                    <li>因應你獨特的需求, 製作適合你的發單程式</li>
                                    <li>將所需資料全面電子化, 往後可以製作各種報表 (例如: 收支表, 追數表, 貨存記錄表等等)</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>6,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢發單工具的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>支票格式自動化</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>一個按鍵，自動轉換成不同的銀行格式支票作列印，並自動輸出銀碼的英文或中文字句</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>600 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢支票格式自動化的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>信封格式自動化</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>一個按鍵，根據資料庫的資料瞬間排好信封格式作列印</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>600 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢信封格式自動化的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>


                {/* 銷售系統 */}
                <div className={PCPcss.ProductType}>
                    <p className={PCPcss.ProductTypeName}>銷售系統</p>
                    {/* <p className={PCPcss.ProductTypeDesc}>為你帶來100%的資料準確性</p>
                <p className={PCPcss.ProductTypeDesc}>一個按鍵，瞬間將資料處理，成為你公司需要的行政/會計報表, 滙入格式或資料輸入</p> */}
                    <hr />
                    <div className={PCPcss.DisplayProduct}>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>發票及報價單系統</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>開單自動化，簡單幾個資料輸入就可以自動生成發票或報價單</li>
                                    <li>連結客戶數據庫，輕鬆管理及開單</li>
                                    <li>連結貨品數據庫，銷售價錢、成本價全部一目了然</li>
                                    <li>合約自動生成功能</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>18,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢發票及報價單系統的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>流程管制系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>所有單據狀況一目了然，令你更妥善安排行動</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>8,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢流程管制系統 (附加)的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>流程審批系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>流程審批功能，令管理更輕鬆</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>8,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢流程審批系統 (附加)的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>連結會計系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>電腦自動將所需資料輸入會計系統(如適用)，不用再人手輸入</li>
                                    <li>自動生成每月/每季/每年銷售資料報告及盈利分析報告</li>
                                    <li>將所需資料全面電子化, 往後可以製作各種報表 (例如: 收支表, 追數表, 貨存記錄表等等)</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>8,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢連結會計系統 (附加)的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>

                {/* 採購及庫存系統 */}
                <div className={PCPcss.ProductType}>
                    <p className={PCPcss.ProductTypeName}>採購及庫存系統</p>
                    {/* <p className={PCPcss.ProductTypeDesc}>為你帶來100%的資料準確性</p>
                <p className={PCPcss.ProductTypeDesc}>一個按鍵，瞬間將資料處理，成為你公司需要的行政/會計報表, 滙入格式或資料輸入</p> */}
                    <hr />
                    <div className={PCPcss.DisplayProduct}>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>採購系統</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>連結供應商數據庫，輕鬆管理及開採購單</li>
                                    <li>簡單幾個資料輸入就可以自動整合報價單</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>18,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢採購系統的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>庫存系統</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>輕鬆管理貨品數量資料庫，即時庫存一目了然</li>
                                    <li>自動入貨、出貨資料輸入 (需配合特定硬件)</li>
                                    <li>輕鬆庫存盤點整合功能</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>18,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢庫存系統的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>流程管制系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>所有採購單據狀況一目了然，令你更妥善安排行動</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>8,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢流程管制系統 (附加)的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>流程審批系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>電腦自動將所需資料輸入會計系統(如適用)，不用再人手輸入</li>
                                    <li>- 自動生成每月/每季/每年銷售資料報告及盈利分析報告</li>
                                    <li>將所需資料全面電子化, 往後可以製作各種報表 (例如: 收支表, 追數表, 貨存記錄表等等)</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>8,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢流程審批系統 (附加)的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>連結會計系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>電腦自動將所需資料輸入會計系統(如適用)，不用再人手輸入</li>
                                    <li>自動生成每月/每季/每年採購報告及成本控制報告</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>8,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢連結會計系統 (附加)的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                    </div>
                    <hr />
                </div>

                {/* 人事管理系統*/}
                <div className={PCPcss.ProductType}>
                    <p className={PCPcss.ProductTypeName}>人事管理系統</p>
                    {/* <p className={PCPcss.ProductTypeDesc}>為你帶來100%的資料準確性</p>
                <p className={PCPcss.ProductTypeDesc}>一個按鍵，瞬間將資料處理，成為你公司需要的行政/會計報表, 滙入格式或資料輸入</p> */}
                    <hr />
                    <div className={PCPcss.DisplayProduct}>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>員工資料庫</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>輕鬆管理員工資料、人手調配</li>
                                    <li>評核、升遷或降職全部記錄清楚</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>8,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢員工資料庫的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>薪酬計算系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>簡單幾個資料輸入就自動計算所有員工薪酬</li>
                                    <li>年假、病假、遲到時間計算並自動調整薪酬</li>
                                    <li>津貼及強積金自動計算並自動調整薪酬</li>
                                    <li>自動生成報稅報告</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>18,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢薪酬計算系統的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>佣金計算系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>簡單幾個資料輸入就自動計算所有員工佣金- 任何複雜的計算方法都能自動演算</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>18,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢佣金計算系統 (附加)的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>奬金計算系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>自動整合資料並計算獎金- 任何複雜的奬金計算方法都能自動演算</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>8,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢流程審批系統 (附加)的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>連結會計系統 (附加)</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>電腦自動將所需資料輸入會計系統(如適用)，不用再人手輸入</li>
                                    <li>自動生成每月/每季/每年採購報告及成本控制報告</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>8,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢連結會計系統 (附加)的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                    </div>
                    <hr />
                </div>

                {/* Excel On-line*/}
                <div className={PCPcss.ProductType}>
                    <p className={PCPcss.ProductTypeName}>Excel On-line</p>
                    <p className={PCPcss.ProductTypeDesc}>將Excel 運作全面升級, 實時資料同步及</p>
                    <p className={PCPcss.ProductTypeDesc}>一個按鍵，瞬間將資料處理，成為你公司需要的行政/會計報表, 滙入格式或資料輸入</p>
                    <p className={PCPcss.ProductTypeDesc}>One Click Done 小程式工具,</p>
                    <p className={PCPcss.ProductTypeDesc}>為你帶來簡易操作的各種功能</p>
                    <hr />
                    <div className={PCPcss.DisplayProduct}>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>Excel On-line</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>使用雲端伺服器, 實現全實時及多機同步資訊的各種使用方法</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>3,000 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢Excel On-line的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                        <div className={PCPcss.EachProduct}>
                            <div className={PCPcss.ProductName}>
                                <p>One Click Done 小程式工具</p>
                            </div>
                            <div className={PCPcss.ProductDescription}>
                                <ul>
                                    <li>簡單操作及便宜價錢, 就能訂製各種方便功能的小程式工具</li>
                                </ul>
                            </div>
                            <div className={PCPcss.ProductOtherDESC}>
                                <p>費用:</p>
                                <p>800 起 + 10% (維護費用為開發費用的10%)</p>
                            </div>
                            <div className={PCPcss.discount}>
                                <p>優惠: </p>
                                <p>送首年維護費用</p>
                            </div>
                            <div className={PCPcss.ContactDiv}>
                                <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢One Click Done 小程式工具的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                            </div>
                        </div>

                    </div>
                    <hr />
                </div>
        </section>
    )
    return content
}
export default PCsystemProduct