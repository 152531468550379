import React, { Dispatch, SetStateAction, createContext, useState } from 'react';
import { IUser } from '../Interface/interface';

interface UserContextType {
  userData: IUser;
  setUserData: Dispatch<SetStateAction<IUser>>;
}

const defaultUserData: IUser = {
  user_id: 0,
  user_name: '',
  email_id: '',
  login_count: 0,
  user_status: '',
  authority_level: '',
  permissions: []
};

export const UserContext = createContext<UserContextType>({
  userData: defaultUserData,
  setUserData: () => {} // Implement a no-op
});