import { useNavigate } from 'react-router-dom';
import { useState, ChangeEvent, useEffect, useContext } from 'react'
import { Cookies } from 'react-cookie';
import Lcss from '../css/Login.module.css'
import axios from 'axios';
import { IToken } from '../Interface/interface';
import { UserContext } from '../Class/UserPara';
//https://www.npmjs.com/package/@types/crypto-js

const cookies = new Cookies();


const Login = () => {

    const { userData, setUserData } = useContext(UserContext);


    const navigate = useNavigate();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [loading, IsLoagin] = useState(false)
    const [visible, setVisible] = useState(false)
    const [codestatus, setCodeStatus] = useState(true)
    const [countlogin, setCountLogin] = useState(0)
    const [loginerrormsg, SetLoginErrorMsg] = useState('您的用戶名或密碼不正確。 請重試！')


    const handleUserInput = (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);
    const handlePasswordInput = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
    const handleRememberMeInput = (e: ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked)

    const togglepassword = () => {
        if (visible === true) {
            setVisible(false)
        } else {
            setVisible(true)
        }
    }

    const LoginBtn = () => {
        IsLoagin(true)
        axios.post(`${process.env.REACT_APP_API_URL}/user/RequestLoginToken`, {
            email_id: username,
            password: password
        })
            .then(function (response) {
                const token: IToken = response.data
                cookies.set('token', token.token)
                getUserData(token.token)
                setCodeStatus(true)
                setCountLogin(0)
            })
            .catch(function (error) {
                // console.log(error);
                setCodeStatus(false)
                setCountLogin(countlogin + 1)
                SetLoginErrorMsg('您的用戶名或密碼不正確。 請重試！')

                if (countlogin >= 20) {
                    const element = document.getElementById("LoginBtn")
                    element?.classList.add(`${Lcss.LoginBtn2}`)
                    element?.classList.remove(`${Lcss.LoginBtn}`)
                    cookies.set('LoginCoolDown', false)
                    SetLoginErrorMsg('抱歉!您的登入嘗試失敗。我們需要強制執行5分鐘的冷卻時間')
                }
            });
    }

    const getUserData = (token: string) => {
        axios.post(`${process.env.REACT_APP_API_URL}/user/GetUserDataByToken`, {}, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(async function (response) {
                let permissionsList = ''; // Initialize as an empty string
                const permissions = response.data.permissions;
                const permissionsSize = permissions.length;

                for (let i = 0; i < permissionsSize; i++) {
                    permissionsList += permissions[i].permission_id;
                    if (i < permissionsSize - 1) {
                        permissionsList += '--';
                    }
                }

                console.log(response.data)

                cookies.set('Uid', response.data.user_id)
                cookies.set('Email', response.data.user_id)
                cookies.set('UserName', response.data.user_id)
                cookies.set('Authority_level', response.data.user_id)
                cookies.set('Login_count', response.data.user_id)
                cookies.set('User_status', response.data.user_id)
                cookies.set('User_status', response.data.permissions)
                cookies.set('PermissionList', permissionsList)
                cookies.set('Login_status', "true")

                setUserData(response.data)

                window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
            })
            .catch(function (error) {
                cookies.remove('Uid')
                cookies.remove('Email')
                cookies.remove('UserName')
                cookies.remove('Authority_level')
                cookies.remove('Login_count')
                cookies.remove('User_status')
                cookies.remove('User_status')
                cookies.remove('PermissionList')
                cookies.remove('Login_status')
                console.log(error);
            });
    }

    const content = (

        <section className={Lcss.MainSection}>
            <div className={Lcss.LoginMain}>

                <div className={Lcss.LoginForm}>



                    <p className={Lcss.Title}>登入</p>

                    <div className={Lcss.Loginlab}>
                        <label htmlFor="exampleInputEmail1" >電子郵件 :</label>
                        <input
                            className={Lcss.LoginTextBox}
                            type="text"
                            id="username"
                            autoComplete="off"
                            value={username}
                            onChange={handleUserInput}
                            required
                        />
                    </div>

                    <div className={Lcss.Loginlab}>
                        <label htmlFor="exampleInputPassword1" >密碼 :</label>
                        <input
                            className={Lcss.LoginTextBox}
                            type={visible ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={handlePasswordInput}
                            required
                        />
                        <span className={Lcss.password_toggle_icon}>
                            {visible ? <i className="fi fi-sr-eye" onClick={togglepassword} /> : <i className="fi fi-sr-eye-crossed" onClick={togglepassword} />}
                        </span>
                    </div>

                    <div className={Lcss.checkboxdiv}>
                        <input
                            type="checkbox"
                            className={Lcss.checkbox}
                            id="persist"
                            onChange={handleRememberMeInput}
                        />
                        <label className="form-check-label" htmlFor="exampleCheck1">保持登入狀態</label><br />
                    </div>

                    {codestatus === false ?

                        <div className={Lcss.LoginStatus}>
                            <p>{loginerrormsg}</p>
                        </div> :
                        null
                    }

                    <button id="LoginBtn" className={Lcss.LoginBtn} onClick={LoginBtn}>登入</button>


                </div>
            </div>
        </section>
    )
    return content
}

export default Login