import axios from 'axios'
import { Cookies } from 'react-cookie';
import { SystemContextType } from '../Class/SystemPara';

const cookies = new Cookies();


export const FetchSystemData = async (systemContext: SystemContextType) => {
    let token = cookies.get('token')

    try {

        console.log("Running FetchSystemData")

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/menu/GetFunction`, {}, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (res.status === 200) {
            systemContext.setFunctionArr(res.data.permissions)
            systemContext.setLoginStatus(true)
        }

    } catch (error) {
        console.log(error)
    }


}

