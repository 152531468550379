import EmailIMG from '../global/email.png'
import PhoneIMG from '../global/telephone-call.png';
import WhatsappIMG from '../global/whatsapp.png';
import LocationIMG from '../global/placeholder.png';
import OpenTime from '../global/working-time.png';
import CTcss from '../css/Contact.module.css'

const Contact = () => {
    return (
        <section className={CTcss.MainSection}>
            <div className={CTcss.ContactCenter}>

                <div className={CTcss.ContactInfo}>
                    <div className={CTcss.ContactImg}>
                        <img src={EmailIMG} className={CTcss.IMG} alt="Email"></img>
                    </div>
                    <div className={CTcss.ContactMessage}>
                        <a href="mailto:info@wisetechinterglobe.com.hk"><p>info@</p>
                            <p>wisetechinterglobe</p>
                            <p>.com.hk</p></a>
                    </div>
                </div>

                <div className={CTcss.ContactInfo}>
                    <div className={CTcss.ContactImg}>
                        <img src={PhoneIMG} className={CTcss.IMG} alt="Phone Number"></img>
                    </div>
                    <div className={CTcss.ContactMessage}>
                        <a href="tel:+85227173421"><p>(+852) 2717 3421</p>
                            <p>(Thomas Ho)</p></a>
                    </div>
                </div>

                <div className={CTcss.ContactInfo}>
                    <div className={CTcss.ContactImg}>
                        <img src={WhatsappIMG} className={CTcss.IMG} alt="WhatsApp"></img>
                    </div>
                    <div className={CTcss.ContactMessage}>
                        <a href="https://wa.me/+85261882830"><p>(+852) 6188 2830</p>
                            <p>(Thomas Ho)</p></a>
                    </div>
                </div>

                <div className={CTcss.ContactInfo}>
                    <div className={CTcss.ContactImg}>
                        <img src={LocationIMG} className={CTcss.IMG} alt="Location"></img>
                    </div>

                    <div className={CTcss.ContactMessage}>
                        <p>香港總部:</p>
                        <a href="https://maps.app.goo.gl/wKfd48S1Vk21UvxU6">
                            <p>九龍, 尖沙咀, 河內道3-4號, 世紀商業大廈, 11樓全層</p>
                        </a>
                    </div>
                    <br />
                    <div className={CTcss.ContactMessage}>
                        <p>香港分部:</p>
                        <a href="https://maps.app.goo.gl/iq3nudD4zQh1TUsG9">
                            <p>九龍, 油塘, 高輝道17號, 油塘工業城A2座, 6樓607室</p>
                        </a>
                    </div>
                </div>

                <div className={CTcss.ContactInfo}>
                    <div className={CTcss.ContactImg}>
                        <img src={OpenTime} className={CTcss.IMG} alt="Working hours"></img>
                    </div>
                    <div className={CTcss.ContactMessage}>
                        <p>辦公時間 : 9:00 - 18:00</p>
                        <p>( 星期一至星期五 )</p>
                        <p>(公眾假期除外)</p>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Contact;
