import PCPcss from '../css/PCsystemProduct.module.css'
const PhoneSystemProduct = () => {
    const content = (
        <section className={PCPcss.sectionMain}>
        <div className={PCPcss.MainLayout}>

            {/* 遙距行應用程式 */}
            <div className={PCPcss.ProductType}>
                <p className={PCPcss.ProductTypeName}>遙距應用程式</p>
                {/* <p className={PCPcss.ProductTypeDesc}>為你帶來100%的資料準確性</p>
                <p className={PCPcss.ProductTypeDesc}>一個按鍵，瞬間將資料處理，成為你公司需要的行政/會計報表, 滙入格式或資料輸入</p> */}
                <hr />
                <div className={PCPcss.DisplayProduct}>

                    <div className={PCPcss.EachProduct}>
                        <div className={PCPcss.ProductName}>
                            <p>貨存管理</p>
                        </div>
                        <div className={PCPcss.ProductDescription}>
                            <ul>
                                <li>貨存出入即時記錄, 即時更新, 隨時隨地查詢貨存資料</li>
                            </ul>
                        </div>
                        <div className={PCPcss.ProductOtherDESC}>
                            <p>費用:</p>
                            <p>15,000 起 + 15% (維護費用為開發費用的10%)</p>
                        </div>
                        <div className={PCPcss.discount}>
                            <p>優惠: </p>
                            <p>送首年維護費用</p>
                        </div>
                        <div className={PCPcss.ContactDiv}>
                            <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢遙距貨存管理應用程式的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                        </div>
                    </div>

                    <div className={PCPcss.EachProduct}>
                        <div className={PCPcss.ProductName}>
                            <p>假期申報</p>
                        </div>
                        <div className={PCPcss.ProductDescription}>
                            <ul>
                                <li>假期 / 例假 即時申報, 總部即時收到並審批</li>
                            </ul>
                        </div>
                        <div className={PCPcss.ProductOtherDESC}>
                            <p>費用:</p>
                            <p>12,000 起 + 15% (維護費用為開發費用的10%)</p>
                        </div>
                        <div className={PCPcss.discount}>
                            <p>優惠: </p>
                            <p>送首年維護費用</p>
                        </div>
                        <div className={PCPcss.ContactDiv}>
                            <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢遙距假期申報應用程式的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                        </div>
                    </div>

                    <div className={PCPcss.EachProduct}>
                        <div className={PCPcss.ProductName}>
                            <p>上下班記錄</p>
                        </div>
                        <div className={PCPcss.ProductDescription}>
                            <ul>
                                <li>自動打卡, 完全代替工卡, 避免人手錯誤, 並自動電子化作計糧處理</li>
                            </ul>
                        </div>
                        <div className={PCPcss.ProductOtherDESC}>
                            <p>費用:</p>
                            <p>12,000 起 + 15% (維護費用為開發費用的10%)</p>
                        </div>
                        <div className={PCPcss.discount}>
                            <p>優惠: </p>
                            <p>送首年維護費用</p>
                        </div>
                        <div className={PCPcss.ContactDiv}>
                            <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢遙距上下班記錄應用程式的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                        </div>
                    </div>

                    <div className={PCPcss.EachProduct}>
                        <div className={PCPcss.ProductName}>
                            <p>程式訂製</p>
                        </div>
                        <div className={PCPcss.ProductDescription}>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                        <div className={PCPcss.ProductOtherDESC}>
                            <p>費用:</p>
                            <p>12,000 起 + 15% (維護費用為開發費用的10%)</p>
                        </div>
                        <div className={PCPcss.discount}>
                            <p>優惠: </p>
                            <p>送首年維護費用</p>
                        </div>
                        <div className={PCPcss.ContactDiv}>
                            <button className={PCPcss.ProductBtn}><a target="_blank" href="https://wa.me/+85261882830?text=你好!%0A想查詢遙距工作安排應用程式的詳細報價。" rel="noreferrer">WhatsApp獲得報價</a></button>
                        </div>
                    </div>

                </div>
                <hr />
            </div>

        </div>
        </section>
    )
    return content
}
export default PhoneSystemProduct