import { Helmet, HelmetProvider } from "react-helmet-async" 
//Pages
import Navbar from "../Components/Navbar"
import { Outlet } from "react-router-dom"
import FootagePage from "../Components/Footage"
import { useContext, useEffect } from "react";
import { FetchSystemData } from "../Interface/Const";
import { SystemContext } from "../Class/SystemPara";


const PublicLayout = () => {

    const systemContext = useContext(SystemContext);
    
    useEffect(() => {
        console.log("Loading User Data")
        FetchSystemData(systemContext)
    }, []);
    
    
    const content = (
        <body>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
                        <title>Wisetechinterglobe</title>
                    </Helmet>
                    <Navbar />
                    <Outlet />
                    <FootagePage />                    
                </div>
            </HelmetProvider>
        </body>
        
    )
    return content
}

export default PublicLayout